/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface TokenResponse {
  accessToken: string;
  /** @default "Bearer" */
  tokenType: string;
  expiresIn: number;
  refreshToken?: string;
  idToken?: string;
}

export interface UserInfo {
  userId: string;
  fiscalCode: string;
  familyName?: string;
  name?: string;
  email?: string;
}

export interface ZendeskAssistanceTokenResponse {
  assistanceToken: string;
  /** URL of the Help Center to return */
  returnTo: string;
  /** URL of Zendesk endpoint */
  actionUrl: string;
}

export interface NoticesListDTO {
  notices?: NoticeDTO[];
}

export interface NoticeDTO {
  eventId: string;
  payeeName?: string;
  payeeTaxCode: string;
  /** @format int64 */
  amount: number;
  /**
   * @format date-time
   * @example "2024-05-31T13:07:25Z"
   */
  noticeDate: string;
  isCart: boolean;
  paidByMe: boolean;
  registeredToMe: boolean;
}

export interface CartItemDTO {
  subject?: string;
  /** @format int64 */
  amount?: number;
  payee?: UserDetailDTO;
  debtor?: UserDetailDTO;
  refNumberValue?: string;
  refNumberType?: string;
}

export interface UserDetailDTO {
  name?: string;
  taxCode?: string;
}

export interface WalletInfoDTO {
  accountHolder?: string;
  brand?: string;
  blurredNumber?: string;
  maskedEmail?: string;
}

export interface NoticeDetailsDTO {
  infoNotice?: InfoNoticeDTO;
  carts?: CartItemDTO[];
}

export interface InfoNoticeDTO {
  eventId: string;
  authCode?: string;
  rrn: string;
  /**
   * @format date-time
   * @example "2024-05-31T13:07:25Z"
   */
  noticeDate: string;
  pspName: string;
  walletInfo?: WalletInfoDTO;
  paymentMethod?: 'BBT' | 'BP' | 'AD' | 'CP' | 'PO' | 'OBEP' | 'JIF' | 'MYBK' | 'PPAL' | 'UNKNOWN';
  payer?: UserDetailDTO;
  /** @format int64 */
  amount: number;
  /** @format int64 */
  fee?: number;
  /** @format int64 */
  totalAmount: number;
  origin: 'INTERNAL' | 'PM' | 'NDP001PROD' | 'NDP002PROD' | 'NDP003PROD' | 'UNKNOWN';
}

export interface PaymentNoticePayloadDTO {
  /** The fiscal code of EC */
  paTaxCode: string;
  /** The name of EC */
  paFullName: string;
  /** @format int64 */
  amount: number;
  /**
   * @minLength 0
   * @maxLength 140
   */
  description: string;
}

export interface PaymentNoticesListDTO {
  paymentNotices?: PaymentNoticeDTO[];
}

export interface PaymentNoticeDTO {
  /** Unique ID of the Debt Position (for positions originating from ACA the IUPD starts with ACA_) */
  iupd: string;
  /** Tax code of the Creditor Body */
  paTaxCode: string;
  /** Company name of the Creditor Body */
  paFullName: string;
  /**
   * State of the Debt Position. Will be
   * VALID
   *  or
   * PARTIALLY_PAID
   */
  status: PaymentNoticeStatus;
  /** Array structure containing any payment options (there always exists at least 1) */
  paymentOptions: PaymentOptionDTO[];
}

/**
 * @format date-time
 * @example "2022-03-10T12:15:50"
 */
export type LocalDateTime = string;

export enum PaymentNoticeStatus {
  VALID = 'VALID',
  PARTIALLY_PAID = 'PARTIALLY_PAID'
}

export interface PaymentOptionDTO {
  /** Description of the payment option */
  description?: string;
  /**
   * Number of installments that make up the payment option, if equal to 1 it is a single payment
   * @format int32
   */
  numberOfInstallments: number;
  /**
   * total amount for the payment option
   * @format int64
   */
  amount?: number;
  /** Is the date by which the Payment option is payable. */
  dueDate?: LocalDateTime;
  /** Indicates whether the OP is part of an installment plan */
  isPartialPayment?: boolean;
  /** Array structure containing the installments that make up the payment option (there always exists at least 1) */
  installments: InstallmentDTO[];
}

export interface InstallmentDTO {
  /** Notice Code */
  nav: string;
  /** Unique identifier */
  iuv: string;
  /** Tax code of the Creditor Body */
  paTaxCode: string;
  /** Company name of the Creditor Body */
  paFullName: string;
  /**
   * Payment Option Amount
   * @format int64
   */
  amount: number;
  /** Description of the OP (e.g. "SISA - 741T, 942T - Checks without authorization or funding") */
  description: string;
  /** Is the date by which (TO) the Payment option is payable. */
  dueDate: LocalDateTime;
  /** Status of the OP */
  status: PaymentOptionStatus;
}

export enum PaymentOptionStatus {
  PO_UNPAID = 'PO_UNPAID',
  PO_PAID = 'PO_PAID',
  PO_PARTIALLY_REPORTED = 'PO_PARTIALLY_REPORTED',
  PO_REPORTED = 'PO_REPORTED'
}

export interface PaymentNoticeDetailsDTO {
  /** Unique ID of the Debt Position (for positions originating from ACA the IUPD starts with ACA_) */
  iupd?: string;
  /** Tax code of the Creditor Body */
  paTaxCode?: string;
  /** Company name of the Creditor Body */
  paFullName?: string;
  status?: PaymentNoticeDetailsStatus;
  /** Array structure containing any payment options (there always exists at least 1) */
  paymentOptions?: PaymentOptionDetailsDTO[];
}

export enum PaymentNoticeDetailsStatus {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
  VALID = 'VALID',
  INVALID = 'INVALID',
  EXPIRED = 'EXPIRED',
  PARTIALLY_PAID = 'PARTIALLY_PAID',
  PAID = 'PAID',
  REPORTED = 'REPORTED'
}

export interface PaymentOptionDetailsDTO {
  /** Notice Code */
  nav?: string;
  /** Unique identifier */
  iuv?: string;
  /**
   * Payment Option Amount
   * @format int64
   */
  amount?: number;
  /** Description of the OP (e.g. "SISA - 741T, 942T - Checks without authorization or funding") */
  description?: string;
  /** Indicates, if set to true, the current PO is a installment */
  isPartialPayment?: boolean;
  dueDate?: LocalDateTime;
  /**
   * corresponds to the SEND notification costs
   * @format int64
   */
  notificationFee?: number;
  status?: PaymentOptionStatus;
}

export interface ErrorDTO {
  error:
    | 'generic_error'
    | 'receipt_not_found_error'
    | 'invalid_amount'
    | 'invalid_date'
    | 'invalid_email'
    | 'invalid_request';
  error_description?: string;
}

import type {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  HeadersDefaults,
  ResponseType
} from 'axios';
import axios from 'axios';

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams
  extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown>
  extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain'
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({
    securityWorker,
    secure,
    format,
    ...axiosConfig
  }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({
      ...axiosConfig,
      baseURL: axiosConfig.baseURL || 'https://${hostname}/arc/v1'
    });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(
    params1: AxiosRequestConfig,
    params2?: AxiosRequestConfig
  ): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method &&
          this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) ||
          {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {})
      }
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === 'object' && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] = property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (type === ContentType.FormData && body && body !== null && typeof body === 'object') {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== 'string') {
      body = JSON.stringify(body);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {})
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path
    });
  };
}

/**
 * @title pagopa-arc-be
 * @version 0.0.1
 * @baseUrl https://${hostname}/arc/v1
 *
 * Api and Models
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  login = {
    /**
     * No description
     *
     * @tags arc auth
     * @name GetAuthenticationEndpoint
     * @summary Provide the authentication endpoint
     * @request GET:/login/oneidentity
     */
    getAuthenticationEndpoint: (params: RequestParams = {}) =>
      this.request<any, void | ErrorDTO>({
        path: `/login/oneidentity`,
        method: 'GET',
        ...params
      })
  };
  token = {
    /**
     * No description
     *
     * @tags arc auth
     * @name GetAuthenticationToken
     * @summary Provide authorization endpoint for exchanging code for token
     * @request GET:/token/oneidentity
     */
    getAuthenticationToken: (
      query: {
        /** The value used for exchanging code for token */
        code: string;
        /** The value of the state this value is handled by the security component */
        state: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<TokenResponse, void | ErrorDTO>({
        path: `/token/oneidentity`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags arc zendesk assistance
     * @name GetZendeskAssistanceToken
     * @summary Retrieve jwt token for the zendesk assistance
     * @request GET:/token/assistance
     * @secure
     */
    getZendeskAssistanceToken: (
      query: {
        /**
         * Required user mail for the assistance
         * @format email
         * @example "someone@example.com"
         */
        userEmail: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<ZendeskAssistanceTokenResponse, ErrorDTO | void>({
        path: `/token/assistance`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      })
  };
  logout = {
    /**
     * No description
     *
     * @tags arc auth
     * @name GetLogoutEndpoint
     * @summary Provide the logout endpoint
     * @request GET:/logout
     */
    getLogoutEndpoint: (params: RequestParams = {}) =>
      this.request<void, void | ErrorDTO>({
        path: `/logout`,
        method: 'GET',
        ...params
      })
  };
  auth = {
    /**
     * No description
     *
     * @tags arc auth
     * @name GetUserInfo
     * @summary Provide information regarding authenticated user
     * @request GET:/auth/user
     * @secure
     */
    getUserInfo: (params: RequestParams = {}) =>
      this.request<UserInfo, void | ErrorDTO>({
        path: `/auth/user`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      })
  };
  notices = {
    /**
     * No description
     *
     * @tags arc notices
     * @name GetNoticesList
     * @summary Retrieve the paged notices list from arc
     * @request GET:/notices
     * @secure
     */
    getNoticesList: (
      query?: {
        /**
         * Number of items for page
         * @format int32
         * @default 10
         */
        size?: number;
        /** The field to filter notices by if is payer */
        paidByMe?: boolean;
        /** The field to filter notices by if is debtor */
        registeredToMe?: boolean;
        /**
         * A value passed to sort by field (Default: TRANSACTION_DATE)
         * @default "TRANSACTION_DATE"
         */
        orderBy?: 'TRANSACTION_DATE';
        /**
         * Direction of ordering (Default: DESC)
         * @default "DESC"
         */
        ordering?: 'ASC' | 'DESC';
      },
      params: RequestParams = {}
    ) =>
      this.request<NoticesListDTO, ErrorDTO | void>({
        path: `/notices`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags arc notices
     * @name GetNoticeDetails
     * @summary Retrieve notice details from arc
     * @request GET:/notices/{eventId}
     * @secure
     */
    getNoticeDetails: (eventId: string, params: RequestParams = {}) =>
      this.request<NoticeDetailsDTO, ErrorDTO | void>({
        path: `/notices/${eventId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags arc notices
     * @name GetNoticeReceipt
     * @summary Retrieve notice receipt from arc
     * @request GET:/notices/{eventId}/receipt
     * @secure
     */
    getNoticeReceipt: (eventId: string, params: RequestParams = {}) =>
      this.request<File, void | ErrorDTO>({
        path: `/notices/${eventId}/receipt`,
        method: 'GET',
        secure: true,
        ...params
      })
  };
  paymentNotices = {
    /**
     * No description
     *
     * @tags arc payment notices
     * @name PostGeneratePaymentNotice
     * @summary Generate payment notice from arc
     * @request POST:/payment-notices
     * @secure
     */
    postGeneratePaymentNotice: (data: PaymentNoticePayloadDTO, params: RequestParams = {}) =>
      this.request<PaymentNoticeDetailsDTO, ErrorDTO | void>({
        path: `/payment-notices`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags arc payment notices
     * @name GetPaymentNotices
     * @summary Retrieve payment notices from arc
     * @request GET:/payment-notices
     * @secure
     */
    getPaymentNotices: (
      query?: {
        /**
         * Optional date to filter paymentNotices (if provided use the format yyyy-MM-dd)
         * @format date
         * @example "2022-03-10"
         */
        dueDate?: string;
        /**
         * Number of elements on one page. Default = 50
         * @format int32
         * @min 1
         * @max 100
         * @default 50
         */
        size?: number;
        /**
         * Page number. Page value starts from 0
         * @format int32
         * @min 0
         * @default 0
         */
        page?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<PaymentNoticesListDTO, ErrorDTO | void>({
        path: `/payment-notices`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      }),

    /**
     * No description
     *
     * @tags arc payment notices
     * @name GetPaymentNoticesDetails
     * @summary Retrieve payment notices details from arc
     * @request GET:/payment-notices/{iupd}
     * @secure
     */
    getPaymentNoticesDetails: (
      iupd: string,
      query: {
        /** Fiscal code of EC */
        paTaxCode: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<PaymentNoticeDetailsDTO, ErrorDTO | void>({
        path: `/payment-notices/${iupd}`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params
      })
  };
}
